import { Router } from "@reach/router"
// import { Layout } from "components/layout"
import { Profile } from "components/profile"
import { LogIn } from "components/user"
import { MemberRoute } from "components/member-route"
import { NotFound } from "components/not-found"
import Lesson2 from "pages/courses/fundamentals/lesson-2.mdx"

const Course = () => (
  <div>Moi</div>
  // <Router basepath="/course">
  //   <MemberRoute path="/profile" component={Profile} />
  //   {/* <MemberRoute path="/fundamentals/lesson-2" component={Lesson2} /> */}
  //   <Lesson2 path="/fundamentals/lesson-2" />
  //   <LogIn path="/login" />
  //   <NotFound default />
  // </Router>
)
export default Course
